body {
    font-family: "proxima nova", sans-serif;
    font-size: 18px;
    font-weight: 300;
    color: #4d4d4d;
    line-height: 1.2;
}
audio,canvas,iframe,
img,svg,video {
    vertical-align: middle;
    max-width: 100%;
}
img {
    height:auto !important;
}
a:link,
a:visited {
    color: inherit;
}
h1,h2,h3 {
    font-family: 'Fjalla One', sans-serif;
    font-weight: normal;
    text-transform: uppercase;
    color: #00a887;
}
h1 { font-size: 42px; }
h2 { font-size: 42px; }
h3 { font-size: 32px; }

h4 {
    text-transform: uppercase;
    color: #00a887;
    font-weight: bold;
    font-size: 18px;
}

.clear-float {
    clear:both;
}

.highlight {
    color: #00a887;
    font-weight: bold;
}

.button,
a.button:link,
a.button:visited {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 30px;
    padding: 10px 15px;
    max-width: 100%;
    color: #00a887;
    font-weight: bold;
    background-color: transparent;
    border: 4px solid #00a887;
    box-sizing: border-box;
    transition: all 0.3s ease;
}
.button:hover,
a.button:hover:link,
a.button:hover:visited {
    color: #FFF;
    background-color: #00a887;
}

img.display-left {
    float:left;
    max-width: 50%;
    margin-right:20px;
    margin-bottom:10px;
}

img.display-right {
    float:right;
    max-width: 50%;
    margin-left:20px;
    margin-bottom:10px;
}

.light-block {
    position: relative;
    background-color: #fcf4f1;
    padding: 5% 0px;
}
.light-block:before,
.light-block:after {
    content:"";
    position: absolute;
    top:0;
    height: 100%;
    width: 1000px;
    background-color: #fcf4f1;
    z-index: 0;
}
.light-block:before {
   right: 100%;
}
.light-block:after {
    left: 100%;
}

.col-wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.two-col {
    display: inline-block;
    vertical-align: top;
    width: 48%;
}

/* food menu style */
.menu-item,
.menu-sub {
    position: relative;
    display: block;
    border-bottom-style: dotted;
    border-color: #4d4d4d;
}
.menu-item:after,
.menu-sub:after {
    content:"";
    display: block;
    width: 100%;
    height: 0px;
    clear: both;
}
.menu-item {
    border-color: #00a887;
    margin: 0px;
    margin-top: 15px;
}
.menu-sub {
    margin: 0px;
    border-width: 2px;
}
.menu-item > span,
.menu-sub > span {
    display: inline-block;
    position: relative;
    bottom: -8px;
    line-height: 1;
    background-color: #f8eadf;
}
.light-block .menu-item > span,
.light-block .menu-sub > span {
     background-color: #fcf4f1;
}
.menu-item > span {
    padding: 2px 2%;
}
.menu-sub > span {
    padding: 2px 1%;
}
.menu-item > span.word,
.menu-sub > span.word {
    padding-left: 0px;
}
.menu-item > span.price,
.menu-sub > span.price {
    float: right;
    padding-right: 0px;
}

@media only screen and (max-width: 760px){
    img.display-left,
    img.display-right {
        float: none;
        max-width: 100%;
        margin: 20px 0;
    }
    .col-wrap {
        display: block;
    }
    .two-col {
        width: 100%;
        padding: 0;
        padding-bottom: 10px; /* for overlap */
    }
}

@media only screen and (max-width: 500px){
/* reduce text **/
    h1, h2 { font-size: 2em; }
    h3 { font-size: 1.5em; }
    .button,
    a.button:link,
    a.button:visited {
        font-size: 20px;
    }
}